.ckEditor .cke_contents {
    height: 400px !important;
}
.jodit-wysiwyg div{
    margin: 0 auto;
}


/* INIT: Styles coming to simulate those from genHTML.service.js See freeflow-api */
/* body{ margin: 8px 16px; }
.btn_plus, .btn_minus {
    background: transparent;
    border: 2px solid #00aded;
    font-size: 28px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    color: #00aded;
    margin-left: 4px;
    margin-right: 4px
}

#btn_play,
#btn_stop {
    height: 37px;
    width: auto;
    border-radius: 5px;
    background-color: #61DAFB;
    border: none;
    cursor: pointer;
    color: white;
    margin-left: 8px;
    font-size: 22px;
    padding: 7px;
}

.goog-te-gadget {
    position: absolute;
    left: 53%;
    top: 6px;
    transform: translateX(-50%);
    color: transparent !important;
}
    
.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    width: 184px;
    height: 37px;
    border-radius: 25px;
    border: none;
    background-color: #F1FBFE;
    color: #00ACED;
    font-size: 18px;
    outline: 0;
    padding-left: 16px;
}

.edit-parent {
    position: relative;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

.goog-logo-link {
    display:none !important;
} 

.line-parent {
    border: 1px solid #00ADED;
    margin-top: 12px; 
}

.text-lg {
    font-size: 27px;
}

.text-md {
    font-size: 20px;
}

.text-sm {
    font-size: 16px;
    line-height: 22px;
    margin: 0.5rem 0;
}

@media (min-width: 768px) {
    .edit-parent, .line-parent {
        margin-left: auto;
        margin-right: auto;
        max-width: 730px;
    }    
    .line-parent {
        margin-top: 24px; 
    }
}

@media (min-width: 575px) {
    .goog-te-gadget .goog-te-combo {
        min-width: 300px
    }
    body {
        margin: 8px;
    }
}
@media (max-width: 575px) {
    img {
        width: 100%;
    }
} */
/* END: Styles coming to simulate those from genHTML.service.js See freeflow-api */